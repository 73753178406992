import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function BrigadeReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Brigade Developers</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">The Brigade Group is one of India’s leading property developers with over three and a half decades of expertise in building positive experiences for all stakeholders. Instituted in 1986, the company has developed many landmark buildings and transformed the skyline of cities across South India, namely – Bengaluru, Mysuru, Mangaluru, Chikmagalur, Hyderabad, Chennai, and Kochi – as well as in GIFT City, Gandhinagar, Gujarat with developments across the Residential, Commercial, Retail, Hospitality, and Education sectors. Since its inception, Brigade has completed 250+ buildings amounting to over 76 million sq. ft of developed space across a diverse real estate portfolio.</p>

            </p>

            {isShowMore && (
                <p className="mb-0">
                    <p className="AboutExpoPara">Brigade is among the few developers that also enjoy a reputation for developing Grade A Commercial properties. The license owners of the World Trade Center across South India, the Group’s commercial spaces have top international clients operating out of them. The brigade also entered the co-working space with ‘BuzzWorks’ in March 2019. The commercial segment has seen consistent growth over the last few years with over 5 million sq. ft of office spaces under development.</p>
                    <p className="AboutExpoPara">Brigade Retail’s first venture was the iconic, world-class Orion Mall at Brigade Gateway, subsequently expanding with Orion Avenue and Orion Uptown. Brigade’s Hospitality offerings include star hotels, recreational clubs, convention centers, and The Baking Company, a unique patisserie.</p>
                    <p className="AboutExpoPara">Brigade is the founder of The Indian Music Experience (IME) – a monumental and philanthropic initiative that is India’s only hi-tech interactive music museum. It was conceived to give back to society and sensitize the present generation to the rich culture of Indian music. The Group contributes in numerous ways towards community development, health, education, and the environment. Through the Brigade Foundation, the brand has successfully opened three schools in its name, offering a holistic education; has conducted large-scale tree plantation drives; spruced up parts of the city; revived public recreational spots like lakes and parks, to name a few. Brigade Real Estate Accelerator Program (REAP), Asia’s first start-up accelerator program, is a prelude to the changing trends in the real estate industry, which is standing on the cusp of disruption. Brigade REAP brings along innovators and inventors to use technology as a catalyst for creating sustainable and scalable businesses in the real estate industry.</p>
                    <p className="AboutExpoPara">For 12 years in a row, ‘The great Place to Work Institute’ has rated the Brigade Group as one of India’s best companies to work for in the real estate industry. This responsible attitude and innovative mindset combined with the uncompromising quality of the projects over the years has created a brand of outstanding repute.</p>

                </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default BrigadeReadMore;